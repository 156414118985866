var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"centrado"},[_c('div',{staticClass:"titulo"},[_vm._v("Haznos llegar tus dudas y consultas a los siguientes contactos")]),_c('div',{staticClass:"lista-contactos"},[(_vm.pregrado)?[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]:[_vm._m(5),_vm._m(6)]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("David Vásquez")]),_c('div',{staticClass:"cargo"},[_vm._v("Encargado de Admisión y Matrícula")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("admision@uaysen.cl")])]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fab fa-whatsapp"}),_vm._v(" Whatsapp: "),_c('span',{staticClass:"resaltar"},[_vm._v("+56939332051")])]),_c('br'),_c('div',{staticClass:"nombre"},[_vm._v("Daniela Vásquez")]),_c('div',{staticClass:"cargo"},[_vm._v("Profesional de Admisión")]),_c('div',{staticClass:"nombre"},[_vm._v("Daniela Miranda")]),_c('div',{staticClass:"cargo"},[_vm._v("Profesional de Admisión")]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Patricia Soto")]),_c('div',{staticClass:"cargo"},[_vm._v("Recaudación y Pagos")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("aranceles@uaysen.cl")])]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Jeannette Monsalve Suter")]),_c('div',{staticClass:"cargo"},[_vm._v("Becas, Beneficios y Gratuidad ")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("becasybeneficios@uaysen.cl")])]),_c('div',{staticClass:"nombre"},[_vm._v("Vanessa Lehue Soto")]),_c('div',{staticClass:"cargo"},[_vm._v("Profesional Becas, Beneficios y Gratuidad ")]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Pablo Olivares")]),_c('div',{staticClass:"cargo"},[_vm._v("Encargado de Biblioteca")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("biblioteca@uaysen.cl")])]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fab fa-whatsapp"}),_vm._v(" Solo Whatsapp: "),_c('span',{staticClass:"resaltar"},[_vm._v("+56 9 3924 2321")])]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Soporte Técnico")]),_c('div',{staticClass:"cargo"},[_vm._v("Contáctanos si tienes un problema con tu correo electrónico")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("soporte@uaysen.cl")])]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Renato Gómez Bascuñán")]),_c('div',{staticClass:"cargo"},[_vm._v("Jefe Carrera Enfermería")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("econtinua.enfermeria@uaysen.cl")])]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contacto"},[_c('div',{staticClass:"nombre"},[_vm._v("Viviana Lehue Ojeda")]),_c('div',{staticClass:"cargo"},[_vm._v("Asistente Administrativa")]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fas fa-at"}),_vm._v(" Email: "),_c('span',{staticClass:"resaltar"},[_vm._v("educacion.continua@uaysen.cl")])]),_c('div',{staticClass:"dato"},[_c('span',{staticClass:"fab fa-whatsapp"}),_vm._v(" Solo Whatsapp: "),_c('span',{staticClass:"resaltar"},[_vm._v("+56 9 8482 8445 ")])]),_vm._v(" Disponible de lunes a viernes de "),_c('b',{staticClass:"resalta"},[_vm._v("9:00 a 18:00 hrs")])])
}]

export { render, staticRenderFns }